<template>
  <div class="google-location-page">
    <vuestic-widget :loading="fetching">
      <template slot="header">
        <span class="title">GMB Integration</span>
      </template>
      <div class="google-location-page-wrapper">
        <div class="row">
          <div class="col-md-12 mb-4">
            <p class="font-weight-bold text-primary">Which location do you want to link to which install?</p>
          </div>
          <div class="col-md-12">
            <span class="font-weight-600">Which Google Account?</span>
            <multiselect class="w-100" v-model="selectedGoogleAccount" :options="googleIntegrations" :multiple="false" :close-on-select="true"  :clear-on-select="false" :preserve-search="true" placeholder="Select Google Account" label="email" track-by="id"  :preselect-first="true" :show-labels="false" :allowEmpty="false" @input="onGoogleAccountSelect"
            ></multiselect>
          </div>
        </div>
        <vuestic-widget :loading="locationFetching">
          <div class="row">
            <div class="col-md-12 mt-4">
              <span class="font-weight-600">Which Location?</span>
              <multiselect class="w-100" v-model="selectedLocation" :options="locations" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Location" label="locationName" track-by="name" :preselect-first="false" :show-labels="false" :allowEmpty="false"
              ></multiselect>
            </div>
            <div class="col-md-12 mt-4">
              <span class="font-weight-600">Which Installs?</span>
              <multiselect class="w-100" v-model="selectedInstall" :options="installs" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Location" label="label" track-by="id" :preselect-first="false" :show-labels="false" :allowEmpty="false"
              ></multiselect>
            </div>
            <div class="col-md-12 mt-4">
              <label class="control-label">Enable GMB call tracking</label>
              <vuestic-switch
                v-model="enableCallTracking"
                class="align-middle"
                style="max-width: 200px"
                :offcolor="true"
              >
                <span slot="trueTitle">Yes</span>
                <span slot="falseTitle">No</span>
              </vuestic-switch>
            </div>
            <div class="col-md-12 mt-5">
              <button class="btn btn-primary" style="min-width: 80px" @click.prevent="onConnect">
                <atom-spinner
                  v-if="processing"
                  slot="loading"
                  :animation-duration="1500"
                  :size="14"
                  color="#FFF"
                  style="margin: auto"
                />
                <span v-else>Connect</span>
              </button>
            </div>
          </div>
        </vuestic-widget>
      </div>
    </vuestic-widget>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  components: {
  },
  data() {
    return {
      locationFetching: false,
      selectedGoogleAccount: null,
      locations: [],
      selectedLocation: null,
      installs: [],
      selectedInstall: null,
      enableCallTracking: false,
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapState('businessIntegration', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
      googleIntegrations: "googleIntegrations",
    }),
  },
  methods: {
    initData() {
      this.$store
        .dispatch("businessIntegration/googleAll")

      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        let tmp = res.data.filter(item => item.status == 'active' && item.active)
        this.installs = tmp.map(item => {
          const phoneNumber = parsePhoneNumberFromString(item.available_number ? item.available_number: '')
          return {
            id: item.id,
            label:( phoneNumber ? phoneNumber.formatNational() + ' : ' : '' ) + item.website
          }
        })
      });
    },

    onGoogleAccountSelect() {
      this.locations = [];
      this.selectedLocation = null;
      this.locationFetching = true
      this.$store
        .dispatch("businessIntegration/getLocationsByGoogleAccount", this.selectedGoogleAccount.id)
        .then((data) => {
          this.locations = data.locations
          this.locationFetching = false
        })
        .catch((err) => {
          this.locationFetching = false
        });
    },

    validate() {
      let result = true
      if (!this.selectedLocation)
      {
        Vue.$toast.open({
          message: 'Please select location.',
          type: 'error',
        })
        result = false
      }

      if (!this.selectedInstall)
      {
        Vue.$toast.open({
          message: 'Please select install.',
          type: 'error',
        })
        result = false
      }
      return result
    },

    onConnect() {
      let valid = this.validate()
      if (!valid) return false

      const params = {
        'google_integration': this.selectedGoogleAccount.id,
        'location': this.selectedLocation.name,
        'install': this.selectedInstall.id,
        'enabled_call_tracking': this.enableCallTracking
      }

      this.$store
        .dispatch("businessIntegration/googleLocationCreate", params)
        .then(() => {
          this.$router.push({ name: 'business.setting' })
        })
        .catch((err) => {
        });
    }
  },
};
</script>
<style lang="scss" scoped>
  .google-location-page-wrapper {
    max-width: 1024px;
  }
</style>

<style lang="scss">
  .google-location-page-wrapper {
    .widget-body {
      padding: 0;
    }
  }
</style>